export default function LandingIntro({
  children,
  bgOpacity,
  bgUrl,
  description,
  heading,
  subheading,
}) {
  return (
    <section className="bg-cover" style={{ backgroundImage: `url(${bgUrl})` }}>
      <div
        className={`bg-gray-600 ${
          bgOpacity ? bgOpacity : "bg-opacity-50"
        } py-12 xl:py-24`}
      >
        <div className="max-w-3xl mx-auto pt-8 px-4 xl:px-0">
          <h1 className="bg-yellow-450 inline-block font-black text-gray-580 px-1 text-2xl uppercase lg:text-6xl">
            {heading}
          </h1>
          <strong className="block font-black text-2xl text-white uppercase lg:text-6xl">
            {subheading}
          </strong>
          <p className="max-w-2xl text-white lg:text-xl">{description}</p>
          {children}
        </div>
      </div>
    </section>
  );
}

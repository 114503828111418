import React from "react";
import dateString from "helpers/dateString";

const BroadcastCard = ({ broadcast }) => {

  const { published_at, content } = broadcast;

  const publishedDate = dateString(published_at);

  const formatContent = content.replace("{{subscriber.rh_reflink}}", "");
  return (
    <div className="flex flex-col ">
      <span className="font-bold mt-6 text-xl text-center py-2">{publishedDate}</span>
      <div className="relative h-[550px] overflow-hidden">
        <div dangerouslySetInnerHTML={{ __html: formatContent }} className="z-0"></div>
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-yellow-50 to-yellow-300 h-1/3 xl:h-1/4 z-10 md:mx-14"/>
      </div>
    </div>
  );
};

export default BroadcastCard;

/* eslint-disable react/jsx-key */
import Head from "next/head";
import Footer from "@components/footer";
import Header from "@components/header";
import LandingIntro from "@components/LandingIntro";
import EmailSignup from "@components/EmailSignup";
import BroadcastCard from "@components/BroadcastCard";

import EmailOutline from "assets/svgs/emailoutline.svg";
import style from "components/footer/style.module.css";
import {
  getSubscribersCount,
  getRecentBroadcastsWithData,
} from "lib/convertkit";

export default function TrueCrime({ totalSubscribers, recentBroadcasts }) {
  
  return (
    <>
      <Head>
        <title>True Crime Community | Uncovered</title>
        <meta
          property="og:image"
          content="https://uncovered.com/wp-content/uploads/2023/01/tcd-social.jpg"
        ></meta>
      </Head>
      <Header />
      <main className="bg-gray-100 pb-8">
        <LandingIntro
          bgUrl="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/7cce74e9-ebf4-4346-518a-32fbc19d3a00/public"
          description={[""]}
          heading="The Citizen Detective"
          subheading="Email Newsletter"
        >
          <article className="pt-4">
            <div className={style.mailchimpform}>
              <span className="absolute transform left-6">
                <EmailOutline />
              </span>
              <EmailSignup
                signupSource={"citizen-detective-landing"}
                isAutoFocused={true}
              />
            </div>
          </article>
        </LandingIntro>
        <section className="longform max-w-3xl mx-auto pt-8 px-4 xl:px-0">
          <p>
            <strong>
              Join {totalSubscribers.toLocaleString()} fellow true crime
              enthusiasts&nbsp;
            </strong>
            and get the 5-minute run-down of what&apos;s going on in the true
            crime world in your inbox every other Tuesday, for free.
          </p>
          <p>
            🔎 The Citizen Detective connects the dots on cold cases, the 
            latest binge-worthy content, and trending mysteries — so you don't have to. 
            Think of us as your online sleuthing friend obsessed with every true crime 
            topic imaginable because… well, we are.
          </p>
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="h-32 md:h-auto p-4">
                <h1 className="text-center font-bold mt-6 text-l">
                  Feeding Curiosity
                </h1>
                <h3 className="mt-6 text-center">
                  Go deeper into the cases and topics that keep us up at night
                </h3>
              </div>
              <div className="h-32 md:h-auto p-4">
                <h1 className="text-center font-bold mt-6 text-l">
                  Hot off the Press
                </h1>
                <h3 className="mt-6 text-center">
                  Explore the latest in high-profile investigations, trials, and content
                </h3>
              </div>
              <div className="h-32 md:h-auto p-4">
                <h1 className="text-center font-bold mt-6 text-l">
                  Compelling Storytelling
                </h1>
                <h3 className="mt-6 text-center">
                  Delve into a case to piece together the truth
                </h3>
              </div>
            </div>
          </div>
          <h2 className="font-bold text-xl mt-8">Why we do it</h2>
          <p>
            There's an endless stream of true crime media — and we know there&apos;s no 
            shortage of cold cases. We spent months looking for the one place to get 
            curated, well-rounded criminally good content.
          </p>
          <p>
            <strong>When we couldn&apos;t find it, we knew we had to create it.</strong>
          </p>
          <p>
            Spend less time trying to get to the end of the rabbit hole, and spend 
            more of your time being informed, with 🔎 The Citizen Detective.
          </p>
        </section>
        <section className="flex longform px-8 pt-8  xl:px-0 flex-col gap-8">
          <h1 className=" text-center font-bold mt-6 text-xl">
            Here’s a sneak peek of what you’ll get in your inbox!
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {recentBroadcasts.map((broadcast) => (
              <BroadcastCard broadcast={broadcast} key={broadcast.id} />
            ))}
          </div>
        </section>
      </main>
      <Footer showEmailSignup={false} />
    </>
  );
}

export async function getStaticProps() {
  const totalSubscribers = await getSubscribersCount();
  const recentBroadcasts = await getRecentBroadcastsWithData(3);

  return {
    props: {
      totalSubscribers,
      recentBroadcasts,
    },
    revalidate: 60 * 15,
  };
}
